import React from "react"
import Head from "next/head"
import { useRouter } from "next/router"
import { OrganizationJsonLd, ArticleJsonLd, WebPageJsonLd } from "next-seo"

const MetaTags = props => {
  const {
    title,
    description,
    image: imageProp = "/assets/Mood-Board-Tool.jpg",
    video,
    videoType = "video/mp4",
    videoWidth,
    videoHeight,
    created,
    authorName,
    authorUrl,
  } = props
  const router = useRouter()
  const site = process.env.NEXT_PUBLIC_SITEURL
  
  // Check if we're on the myroomdesigner.ai domain
  const isMyroomdesignerDomain = typeof window !== 'undefined' && window.location.hostname === 'myroomdesigner.ai'
  
  // Check if the path starts with interior-design-ai
  const isInteriorDesignAI = router.asPath.startsWith("/interior-design-ai/")
  
  // Check if it's the main page by checking both pathname and asPath
  // asPath includes query parameters, pathname is just the route
  // We also need to handle trailing slashes
  const cleanPath = path => path.replace(/\/$/, '').split('?')[0]
  const isInteriorDesignAIMainPage = cleanPath(router.asPath) === '/interior-design-ai'
  
  // Check if we're at the root path (/ or empty) on myroomdesigner.ai domain
  const isRootPathOnMyroomdesigner = isMyroomdesignerDomain && (cleanPath(router.asPath) === "" || cleanPath(router.asPath) === "/")

  // Check if we should use the MyRoomDesigner branding
  const isMyRoomDesignerSite = isMyroomdesignerDomain || isInteriorDesignAI || isInteriorDesignAIMainPage || isRootPathOnMyroomdesigner

  // Determine the full URL based on the conditions
  let url
  if (isRootPathOnMyroomdesigner) {
    // If we're on the root path of myroomdesigner.ai, use the site URL + /interior-design-ai
    url = site + "/interior-design-ai"
  } else if (isInteriorDesignAIMainPage) {
    // If we're on /interior-design-ai page, use the site URL + /interior-design-ai
    url = site + "/interior-design-ai"
  } else if (router.asPath === "/interior-design-ai/designer") {
    // If we're on /interior-design-ai/designer, use the site URL + /interior-design-ai
    url = site + "/interior-design-ai"
  } else if (isMyroomdesignerDomain || isInteriorDesignAI) {
    // If we're on myroomdesigner.ai domain or interior-design-ai path, use site URL + path
    url = site + cleanPath(router.asPath)
  } else {
    // For all other cases, use the site URL with the current path
    url = site + cleanPath(router.asPath)
  }

  const url_de = site + "/de" + router.asPath
  const image = imageProp?.startsWith("http") ? imageProp : site + imageProp
  const videoUrl = video?.startsWith("http") ? video : site + video

  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />

        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
        <meta itemProp="image" content={image} />

        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        {video && videoWidth && videoHeight && (
          <>
            <meta name="og:video" content={videoUrl} />
            <meta name="og:video:type" content={videoType} />
            <meta name="og:video:width" content={videoWidth} />
            <meta name="og:video:height" content={videoHeight} />
          </>
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" type="image/png" href="/logo.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#a42959" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <OrganizationJsonLd
        type="OnlineBusiness"
        logo={`${site}/logo.png`}
        name={isMyRoomDesignerSite ? "My Room Designer AI" : "MoodBoardly"}
        url={site}
      />
      <WebPageJsonLd description={description} id={url} />
      {created && (
        <ArticleJsonLd
          url={url}
          title={title}
          description={description}
          images={[image]}
          datePublished={created}
          authorName={[
            {
              name: authorName,
              url: authorUrl,
            },
          ]}
        />
      )}
    </>
  )
}

export default MetaTags
