import React, { useState } from 'react'
import { Check } from '@styled-icons/boxicons-regular/Check'
import { X } from '@styled-icons/boxicons-regular/X'
import { InfoCircle } from '@styled-icons/boxicons-regular/InfoCircle'
import { ChevronDown } from '@styled-icons/boxicons-regular/ChevronDown'
import { ChevronUp } from '@styled-icons/boxicons-regular/ChevronUp'

const conditionMessages = {
  isUnfurnished: {
    true: "Room is unfurnished",
    false: "Room has essential furniture"
  },
  isConstruction: {
    true: "Room is under construction",
    false: "Room is fully constructed"
  },
  isWideAngle: {
    true: "Photo taken with wide-angle lens (may affect generation quality)",
    false: "Photo taken at normal angle"
  },
  isScreenshot: {
    true: "Image is a screenshot or render (may affect generation quality)",
    false: "Real photo detected"
  },
  isMessy: {
    true: "Room appears cluttered or messy",
    false: "Room is tidy"
  },
  isBlurry: {
    true: "Photo quality is low or blurry",
    false: "Photo quality is good"
  }
}

export const LayoutConditionsDisplay = ({ conditions }) => {
  const [showAll, setShowAll] = useState(false);
  if (!conditions) return null

  // Separate critical issues from info messages
  const criticalIssues = Object.entries(conditions).filter(([condition, value]) => {
    if (!conditionMessages[condition]) return false;
    if (condition === 'isUnfurnished' || condition === 'isConstruction') return false;
    return value === true;
  });

  const infoMessages = Object.entries(conditions).filter(([condition, value]) => {
    if (!conditionMessages[condition]) return false;
    return (condition === 'isUnfurnished' || condition === 'isConstruction') && value;
  });

  // If no issues or info messages, show compact success
  if (criticalIssues.length === 0 && infoMessages.length === 0) {
    return (
      <div className="p-3 bg-gray-50 rounded-lg">
        <button
          onClick={() => setShowAll(!showAll)}
          className="w-full flex items-center justify-between gap-2 text-left"
        >
          <div className="flex items-center gap-2">
            <Check className="w-5 h-5 text-green-500" />
            <span className="text-sm text-green-700">Photo quality looks good</span>
          </div>
          {showAll ? (
            <ChevronUp className="w-4 h-4 text-gray-600" />
          ) : (
            <ChevronDown className="w-4 h-4 text-gray-600" />
          )}
        </button>
        {showAll && (
          <div className="mt-3 space-y-2">
            {Object.entries(conditions).map(([condition, value]) => {
              if (!conditionMessages[condition]) return null;
              return (
                <div key={condition} className="flex items-center gap-2">
                  <Check className="w-5 h-5 text-green-500" />
                  <span className="text-sm text-green-700">
                    {conditionMessages[condition][value.toString()]}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="p-4 bg-gray-50 rounded-lg">
      <button
        onClick={() => setShowAll(!showAll)}
        className="w-full flex items-center justify-between gap-2 text-left"
      >
        <div>
          {criticalIssues.length > 0 && (
            <div className="flex items-center gap-2">
              <X className="w-5 h-5 text-red-500" />
              <span className="text-sm text-red-700">
                {conditionMessages[criticalIssues[0][0]][criticalIssues[0][1].toString()]}
                {criticalIssues.length > 1 && ` (+${criticalIssues.length - 1} more)`}
              </span>
            </div>
          )}
          {criticalIssues.length === 0 && infoMessages.length > 0 && (
            <div className="flex items-center gap-2">
              <InfoCircle className="w-5 h-5 text-blue-500" />
              <span className="text-sm text-blue-700">Photo analysis available</span>
            </div>
          )}
        </div>
        {showAll ? (
          <ChevronUp className="w-4 h-4 text-gray-600" />
        ) : (
          <ChevronDown className="w-4 h-4 text-gray-600" />
        )}
      </button>

      {showAll && (
        <div className="mt-3 space-y-2">
          {criticalIssues.length > 0 && (
            <>
              {criticalIssues.length > 1 && <h4 className="font-semibold text-gray-900 text-sm">All Issues</h4>}
              {criticalIssues.slice(1).map(([condition, value]) => (
                <div key={condition} className="flex items-center gap-2">
                  <X className="w-5 h-5 text-red-500" />
                  <span className="text-sm text-red-700">
                    {conditionMessages[condition][value.toString()]}
                  </span>
                </div>
              ))}
            </>
          )}
          
          {infoMessages.length > 0 && (
            <>
              {criticalIssues.length > 0 && <div className="my-3 border-t border-gray-200" />}
              {infoMessages.map(([condition, value]) => (
                <div key={condition} className="flex items-center gap-2">
                  <InfoCircle className="w-5 h-5 text-blue-500" />
                  <span className="text-sm text-blue-700">
                    {conditionMessages[condition][value.toString()]}
                  </span>
                </div>
              ))}
            </>
          )}

          {/* Show passed checks */}
          {(criticalIssues.length > 0 || infoMessages.length > 0) && (
            <div className="my-3 border-t border-gray-200" />
          )}
          {Object.entries(conditions).map(([condition, value]) => {
            if (!conditionMessages[condition]) return null;
            if (value) return null; // Skip issues and info messages already shown
            return (
              <div key={condition} className="flex items-center gap-2">
                <Check className="w-5 h-5 text-green-500" />
                <span className="text-sm text-green-700">
                  {conditionMessages[condition][value.toString()]}
                </span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}