import React from "react"
import Image from 'next/future/image'

const FeaturesWidget = () => {
  return (
    <section className="relative overflow-hidden pt-28 pb-44">
      <div className="relative z-10 container mx-auto px-4">
        <div className="md:mx-auto md:max-w-6xl">
          <div className="flex flex-wrap -m-5">
            {/* First Card */}
            <div className="w-full md:w-1/2 p-5 md:mt-auto">
              <div className="p-9 transform hover:-translate-y-3 rounded-2xl transition ease-out duration-1000 bg-[#f7f6f4]">
                <div className="pb-0 p-6 mb-6">
                  <p className="mb-4 font-heading text-base text-opacity-70">Mood Board AI</p>
                  <h3 className="font-heading text-3xl">Effortless Mood Boards Creation</h3>
                </div>
                <ul className="inline-block mb-6 max-w-sm py-4 justify-center text-center">
                  {[
                    'Mood Board Creation With One-Click',
                    'For Interior Design, Fashion, and More',
                    'Style and Color Selection',
                    'Additional Customization',
                    'Easy Item Removal & Clean Up',
                    'Seamless import into Mood Board Editor'
                  ].map((feature, index) => (
                    <li key={index} className="flex items-center font-heading mb-3 font-medium text-base text-gray-900 text-center md:text-left">
                      <svg className="mr-2.5 shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 5.29289C17.0976 5.68342 17.0976 6.31658 16.7071 6.70711L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L8 12.5858L15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289Z" fill="#14B082"/>
                      </svg>
                      <p>{feature}</p>
                    </li>
                  ))}
                </ul>
                <div className="flex space-x-4 mb-6 pl-6">
                  <a href="https://moodboardai.com/ai-generator" className="group relative flex font-heading block py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2 ring-gray-700 hover:ring-gray-500 mx-auto md:mx-0 ring-1 bg-[#fdfdfc]">
                    Create mood board
                  </a>
                </div>
              </div>
            </div>

            {/* Second Card */}
            <div className="w-full md:w-1/2 p-5">
              <div className="flex flex-col justify-between h-full transform hover:-translate-y-3 overflow-hidden rounded-2xl transition ease-out duration-1000 bg-[#f7f6f4] p-9">
                <div className="pb-0 p-6 mb-6">
                  <h3 className="mb-5 font-heading text-3xl">AI-generated Mood Boards</h3>
                  <p className="mb-4 font-heading text-base text-opacity-70">Whether for interior design, fashion, or restaurant branding, Mood Board AI turns ideas into stunning mood boards effortlessly.</p>
                </div>
                <Image 
                  className="mx-auto" 
                  src="https://storage.googleapis.com/moodly-16ff8.appspot.com/ai-generator/gr24ghzb6nrmc0ckvwrbbrfy24.jpg" 
                  alt="AI-generated Mood Boards"
                  width={2000}
                  height={2000}
                />
              </div>
            </div>

            {/* Third Card */}
            <div className="w-full md:w-1/2 p-5">
              <div className="flex flex-col justify-between transform hover:-translate-y-3 overflow-hidden rounded-2xl transition ease-out duration-1000 bg-[#f7f6f4] p-9">
                <div className="pb-0 p-6 mb-6">
                  <h3 className="mb-5 font-heading text-3xl">Customize freely</h3>
                  <p className="mb-4 font-heading text-base text-opacity-70">Upscale, find real life items from your board, or import your AI-generated mood board into a Mood Board Editor for further customization.</p>
                </div>
                <Image 
                  className="mx-auto" 
                  src="/assets/Mood-Board-AI23.jpeg" 
                  alt="Customize Mood Boards"
                  width={2000}
                  height={2000}
                />
              </div>
            </div>

            {/* Fourth Card */}
            <div className="w-full md:w-1/2 p-5">
              <div className="p-9 transform hover:-translate-y-3 rounded-2xl transition ease-out duration-1000 bg-[#f7f6f4]">
                <video className="mx-auto w-full h-full object-cover" loop muted autoPlay>
                  <source src="/assets/mood-board-videos/Item-Removal-Mood-Board-AI.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="pb-0 p-6 mb-6">
                  <h3 className="mb-5 font-heading text-3xl">Easy item removal & clean up</h3>
                  <p className="mb-4 font-heading text-base text-opacity-70">With our Mood Board Editor, you can create beautiful mood boards and easily make a shopping list of your favorite items, complete with prices to share with others.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesWidget
