import React from "react"
import LoginOrProfile from "./LoginOrProfile"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Logo from "./BoardLogo"
import { Disclosure, Menu, Transition } from "@headlessui/react"

const myRoomDesignerDomains = ["myroomdesigner.ai", "www.myroomdesigner.ai"]

function checkIsMyRoomDesignerAI(path) {
  if (typeof window !== "undefined") {
    return (
      myRoomDesignerDomains.includes(window.location.hostname) ||
      path.startsWith("/interior-design-ai")
    )
  }
  return path.startsWith("/interior-design-ai")
}

const NavLink = React.forwardRef((props, ref) => (
  <a
    {...props}
    ref={ref}
    className={`block px-5 py-3 text-sm text-gray-900 transition-all duration-200 ease-in  hover:translate-x-2 ${
      props.current ? 'translate-x-2 font-bold' : ''
    }`}
  >
    {props.children}
  </a>
))

const Button = React.forwardRef((props, ref) => (
  <button
    {...props}
    ref={ref}
    className="inline-flex items-center justify-center p-2 rounded-full text-pink-800 focus:outline-none "
  >
    {props.children}
  </button>
))

export default function NavBar({
  sticky = true, 
  gradient = false, 
  transparent = false, 
  bgColor,
  createBoardLink = '/ai-generator'
}) {
  const router = useRouter()
  const { t } = useTranslation()

  const isMyRoomDesignerAI = checkIsMyRoomDesignerAI(router.asPath)

  const navigation = [
    {
      name: t("hero.button"),
      href: createBoardLink,
      highlight: true,
      mobileOnly: true,
      showOnMyRoomDesignerAI: false,
    },
    {
      name: "Design a room",
      href: "/interior-design-ai/designer",
      highlight: true,
      mobileOnly: true,
      showOnMyRoomDesignerAI: true,
    },
    {
      name: t("nav.ai"),
      href: "https://moodboardai.com/",
      showOnMyRoomDesignerAI: true,
    },
    {
      name: "Mood Board Editor",
      href: "https://moodboardai.com/mood-board-editor",
      showOnMyRoomDesignerAI: true,
    },
    {
      name: "MyRoomDesigner.AI",
      href: "/interior-design-ai",
      showOnMyRoomDesignerAI: true,
    },
  ]

  return (
    <Disclosure
      as="nav"
      className={`${sticky ? 'sticky' : transparent ? 'absolute' : ''} top-0 z-20  ${gradient ? 'animated-gradient-bg bg-gradient-to-r from-red-100 via-blue-50 to-gray-50' : transparent ? 'bg-transparent w-full' : bgColor ? bgColor : 'bg-white/80 backdrop-blur-md'}`}
    >
      {({ open }) => (
        <div>
          <div className="mx-auto md:px-8 px-4 md:py-2 py-1">
            <div className="flex items-center justify-between">
              <div className="lg:hidden order-1">
                <Disclosure.Button as={Button}>
                  <svg
                    className="text-gray-800"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {open ? (
                      <path
                        d="M19 5L5 19M5 5L19 19"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    ) : (
                      <path
                        d="M4 12H20M4 6H20M4 18H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    )}
                  </svg>
                </Disclosure.Button>
              </div>
              <div className="flex items-center order-2 lg:order-1">
                <div className="absolute left-1/2 -translate-x-1/2 lg:static lg:left-auto lg:transform-none lg:mr-4">
                  <a href="/">
                    <Logo className="h-12 w-12" />
                  </a>
                </div>
                <div className="hidden lg:block relative">
                  <Menu as="div" className="relative inline-block text-left outline-none ring-0 appearance-none">
                    {({ open }) => (
                      <>
                        <Menu.Button className="text-gray-900 hover:text-gray-700 outline-none ring-0 font-medium text-sm px-3 py-2 inline-flex items-center focus:outline-none focus:ring-0 appearance-none">
                          <span className="font-heading mr-2 text-sm">
                            {isMyRoomDesignerAI ? "MyRoomDesigner.AI" : "Mood Board AI"}
                          </span>
                          <svg
                            className={`h-4 w-4 transform transition-transform duration-200 ${
                              open ? 'rotate-180' : ''
                            }`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </Menu.Button>
                        <Transition
                          as={React.Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute font-heading left-0 mt-3 w-56 bg-white backdrop-blur-md divide-y divide-gray-100 rounded-xl shadow-lg">
                            <div className="py-3 px-3">
                              {navigation
                                .filter(item => !item.mobileOnly)
                                .filter(
                                  item =>
                                    !isMyRoomDesignerAI || item.showOnMyRoomDesignerAI,
                                )
                                .map(({ name, href, current, onClick }) => (
                                  <Menu.Item key={name}>
                                    {({ active }) => (
                                      <NavLink
                                        href={href}
                                        current={router.asPath === href}
                                        onClick={onClick}
                                        className={`block px-6 py-4 text-sm text-gray-900 transition-all duration-200 rounded-md ${
                                          active ? 'translate-x-2' : ''
                                        }`}
                                      >
                                        {name}
                                      </NavLink>
                                    )}
                                  </Menu.Item>
                                ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
              <div className="flex items-center gap-4 order-3">
                <LoginOrProfile />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden bg-white">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation
                .filter(
                  item => !isMyRoomDesignerAI || item.showOnMyRoomDesignerAI,
                )
                .map(({ name, href, highlight, onClick }) => (
                  <Disclosure.Button
                    key={name}
                    as="a"
                    href={href}
                    className={`block px-3 py-2 rounded-md text-base font-medium ${
                      highlight
                        ? "text-white bg-pink-900"
                        : "text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    }`}
                    aria-current={router.asPath === href ? "page" : undefined}
                    onClick={onClick}
                  >
                    {name}
                  </Disclosure.Button>
                ))}
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  )
}
