import React, { useState, useEffect, createContext } from 'react'
import app, { boards as boardsDb, users as usersDb, customers, productImages as productImagesRef, boardImages as boardImagesRef, loadBoardImageUrl, loadUserPublicDetailsFromUid, profilePhotos as profilePhotosRef, userInputImages as userInputImagesRef} from "./FirebaseApp"
import 'firebase/analytics'
import { useRouter } from 'next/router'
import firebase from "firebase/auth"
import { nanoid } from 'nanoid'
import { hotjar } from "react-hotjar"

const FirebaseContext = createContext(null)
export { FirebaseContext }

const FirebaseAnalytics = ({app}) => {
    const location = useRouter()
    
    useEffect(() => {
        if(typeof window === "undefined") return
        
        const analytics = app.analytics
        if (typeof analytics === "function") {
            const locale = location.locale != location.defaultLocale ? `/${location.locale}` : ""
            const page_path = locale + location.asPath;
            analytics().setCurrentScreen(page_path);
            analytics().logEvent("page_view", { page_path });
        }
    }, [location, app]);
    return null;
}

const Firebase = ({ children }) => {
    const auth = useProvideAuth()

    return (
      <FirebaseContext.Provider value={ auth }>
        { children }
        <FirebaseAnalytics app={auth.app}/>
      </FirebaseContext.Provider>
    )
  };

export default Firebase;

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [userPublic, setUserPublic] = useState(null)
  const [isAdmin, setIsAdmin] = useState(null)
  const [stripeRole, setStripeRole] = useState(null)
  const [streamUserToken, setStreamUserToken] = useState(null)

  const signout = () => {
    return app
      .auth()
      .signOut()
      .then(() => {
        setUser(false)
        setUserPublic(false)
      });
  };

  const signInAnonymouslyAndGetUser = async () => {
    // Returns the new user or if already signed in the current user
    const userResponse = await app.auth().signInAnonymously()
    return userResponse.user
  }


  useEffect(() => {
    if (stripeRole) {
      hotjar.initialize(
        process.env.NEXT_PUBLIC_HOTJAR_HJID,
        process.env.NEXT_PUBLIC_HOTJAR_HJSV,
      )
    }
  }, [stripeRole])

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = app.auth().onAuthStateChanged(user => {
      console.log("authentication changed", user)
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  
  useEffect(() => {
      if (!user || user.isAnonymous) {
        if(user === false) setUserPublic(false)
        setIsAdmin(null)
        return
      }

      loadOrCreatePublicUser(user)
      _getIdTokens()
  }, [user]);


  useEffect(() => {
    if (!userPublic || !userPublic.photoURL) {
      if(userPublic === false) {
        localStorage.removeItem('userPublic')
        setStreamUserToken(null)
      }
      return
    }

    localStorage.setItem('userPublic', JSON.stringify(userPublic))
    const getOrCreateStreamUserToken = async () => {
      setStreamUserToken(await getStreamUserToken(userPublic))
    }
    getOrCreateStreamUserToken()
}, [userPublic]);


  const findUserData = (user, key) => {
    if(!user) return

    if(user[key]) return user[key]

    if(user.providerData) {
        const providerDataRes = user.providerData.find(f => f[key])
        return providerDataRes && providerDataRes[key]
    }
}

  const loadUserPublicDetails = async user => {
    return await loadUserPublicDetailsFromUid(user.uid)
  }

  const generateUserName = async fullName => {
    let username = fullName.replace(' ', '')
     username = username.replace(/\s+/g, '')
     username = username.replace(/\'+/g, '')
     username = username.replace(/-+/g, '')
     const usernameLowercase = username.toLowerCase()
     const userRef = await usersDb()
     const usersRes = await userRef.doc(usernameLowercase).get()
     if(!usersRes.exists) return usernameLowercase
     return `${username.toLowerCase()}-${nanoid(6)}`

  }

  const createUserPublicDetails = async user => {
    const { uid } = user
    const photoURL = findUserData(user, "photoURL")  || '/assets/no-profile-picture-icon.png'
    const displayName = findUserData(user, "displayName")

    if(!displayName) {
      console.error("no displayName defined")
      return
    }
    const username = await generateUserName(displayName)

    const userPublic = {
      uid,
      photoURL,
      displayName,
      username
    }

    try {
      const user = await usersDb()
      const doc = user.doc(username)
      await doc.set(userPublic, { merge: true })
      
      console.log("User public created with id: ", doc.id)
      return userPublic
    }
    catch(error) {
        console.error("Error creating document: ", error);
    }
  }
  

  const loadOrCreatePublicUser = async user => {
    let userPublic = await loadUserPublicDetails(user)
    if(!userPublic) {
      userPublic = await createUserPublicDetails(user)
    }

    setUserPublic(userPublic)
  }

  const uploadImage = async (id, imageBlob, imagesRef, meta, fileName = `${id}.png`) => {
    if(!user) await signInAnonymouslyAndGetUser()
    const imageRef = imagesRef.child(fileName);

    const snapshot = await imageRef.put(imageBlob, meta)
    const downloadURL = await snapshot.ref.getDownloadURL()
    return downloadURL
  }

  const uploadProductImage = async (id, imageBlob) => {
    const listRef = await productImagesRef()
    return uploadImage(id, imageBlob, listRef, {
      cacheControl: 'public,max-age=4000',
    })
  } 

  const uploadUserInputImages = async (id, imageFile) => {
    let fileName = `${id}.png` // Default extension
    let imageBlob = imageFile

    // Check if the image is in HEIC/HEIF format
    if (imageFile.type === "image/heic" || imageFile.type === "image/heif") {
      try {
        const heic2any = (await import("heic2any")).default
        imageBlob = await heic2any({
          blob: imageFile,
          toType: "image/jpeg",
          quality: 0.8, // Adjust quality as needed
        })

        // Update fileName to reflect JPEG format after conversion
        fileName = `${id}.jpg`
      } catch (error) {
        console.error("Error converting HEIC/HEIF image:", error)
        throw error // Handle conversion errors appropriately
      }
    } else {
      if (imageFile.type === "image/jpeg") {
        fileName = `${id}.jpg`
      } else if (imageFile.type === "image/png") {
        fileName = `${id}.png`
      } // Add more conditions as necessary for other file types
    }

    // Proceed to upload the image
    const userInputRef = await userInputImagesRef()
    return uploadImage(
      id,
      imageBlob,
      userInputRef,
      {
        cacheControl: "public,max-age=3600",
      },
      fileName,
    )
  }
  
  


  const uploadProfilePhoto = async (imageBlob) => {
    const photosRef = await profilePhotosRef()
    const imageRef = photosRef.child(`${user.uid}/profile.jpg`);

    const snapshot = await imageRef.putString(imageBlob, 'data_url')
    const downloadURL = await snapshot.ref.getDownloadURL()
    return downloadURL
  } 

  const updateProfilePhoto = async (imageBlob) => {
    const photoURL = await uploadProfilePhoto(imageBlob)
    const user = await usersDb()
    const doc = user.doc(userPublic.username)
    await doc.set({photoURL}, { merge: true })
    setUserPublic(userPublic => {return {...userPublic, photoURL}})
  }

  const listProductImages = async () => {
    try {
        const images = []
        const listRef = await productImagesRef()
        // Find all the prefixes and items.
        const res = await listRef.listAll()
        for (let i = 0; i < res.items.length; i++) {
          images.push(await res.items[i].getMetadata())
          console.log("image data", images)
        }
        console.log("images loaded",images)
        return images
      }
        catch(error) {
          throw error
        }
  }

  const uploadBoardImage = async (id, imageBlob) => {
    const boardImages = await boardImagesRef()
    return uploadImage(id, imageBlob, boardImages, {
      cacheControl: 'public,max-age=604800', //a week
    }, `${id}.jpg`)
  }

  const deleteImage = async (id, imagesRef, fileName = `${id}.png`) => {
    const imageRef = imagesRef.child(fileName);

    await imageRef.delete()
  }

  const deleteBoardImage = async id => {
    const boardImages = await boardImagesRef()
    return await deleteImage(id, boardImages, fileName = `${id}.jpg`)
  }

  const deleteProductImage = async id => {
    const productImages = await productImagesRef()
    return await deleteImage(id, productImages)
  }

  
  const createBoard = async (products, canvas) => {
    try {
      const currentUser = user || await signInAnonymouslyAndGetUser()
      const boardsRef = await boardsDb()
      const board = await boardsRef.doc() 
      await board.set({
        user: currentUser.uid,
        created: app.firestore.FieldValue.serverTimestamp(),
        products: products ? products : {},
        canvas: canvas ? canvas : {},
        isImageDirty: canvas ? true : false,
      })
      return board.id
    }
    catch(error) {
      return error;
    }
  }

  const deleteBoard = async id => {
    try {
        const boards = await boardsDb()
        const board = boards.doc(id)
        await board.delete()
        await deleteBoardImage(id)
    }
    catch(error) {
      return error
    }
  }
  
  const saveBoard = async (board) => {
    try {
        console.log("board state to save",board)
        const {id, ...data} = board 
        const boards = await boardsDb()
        const boardDoc = boards.doc(id)
        
        await boardDoc.set({
            ...data,
            isImageDirty: true,
            modified: app.firestore.FieldValue.serverTimestamp(),
        });

        console.log("Board state written to id: ", boardDoc.id)
    }
    catch(error) {
      return error;
    }
  }

  const isOwner = uid => {
    return user?.uid === uid
  }

  const _getIdTokens = async () => {
    await user.getIdToken(true)
    const idTokenResult = await user.getIdTokenResult()
    setIsAdmin(!!idTokenResult.claims.isAdmin)
    setStripeRole(idTokenResult.claims.stripeRole)
  }

  const getStreamUserToken = async (userPublic) => {
    await import("firebase/functions")
    const createStreamUserToken = app.functions().httpsCallable('createStreamUserToken')
    const streamTokenResult = await createStreamUserToken(userPublic)
    const streamToken = streamTokenResult.data
    console.log("streamToken loaded from Firebase function", streamToken)
    return streamToken
  }

  const logEvent = (name, params) => {
    if(window.location.hostname === "localhost") {
      console.log("Log Event", name, params)
      return
    }
    app.analytics().logEvent(name, params)
  }
  
  // Return the user object and auth methods
  return {
    app,
    user,
    userPublic,
    signout,
    signInAnonymouslyAndGetUser,
    loadProductImage,
    uploadProductImage,
    uploadUserInputImages,
    uploadBoardImage,
    createBoard,
    loadBoardOnce,
    saveBoard,
    deleteBoard,
    deleteProductImage,
    isOwner,
    isAdmin,
    stripeRole,
    listProductImages,
    logEvent,
    streamUserToken,
    updateProfilePhoto
  };
}

const loadProductImage = async (id) => {
  const fileName = `${id}.png`
  const productImages = await productImagesRef()
  return loadImage(fileName, productImages)
}

const loadImage = async (fileName, imagesRef) => {
  try {
    const imageRef = imagesRef.child(fileName)
    return await imageRef.getDownloadURL()
  }
  catch(error) {
    if(error.code == 'storage/object-not-found') {
      return
    }
    return new Error(error)
  }
} 

const loadBoardOnce = async id => {
  try {
      const boards = await boardsDb()
      const board = await boards.doc(id).get() 
      if (!board.exists) {
        return new Error("Board could not be found.")
      }
      const image = await loadBoardImageUrl(id)
      const data = board.data()
      return {id, ...data, image}
  }
  catch(error) {
    return error;
  }
}

const listBoards = async ({filterUser, showPublicOnly = true, limit = 20, sortBy = {field: "created", order:'desc'}, startAfter}) => {
  try {
      const boardsRef = await boardsDb()
      let query = boardsRef
      if(filterUser) query = addWhereToQuery(query, ["user", "==", filterUser])
      if(showPublicOnly) query = addWhereToQuery(query, ["status", "==", "public"])
      query = query.orderBy(sortBy.field, sortBy.order)
      if (startAfter) {
        query = query.startAfter(startAfter[sortBy.field])
      }
      const boardsDbRes = await query
            .limit(+limit)
            .get()

      const boards = await Promise.all(
        boardsDbRes.docs.map(async doc => {
          const image = await loadBoardImageUrl(doc.id)
          return { id: doc.id, ...doc.data(), image }
        }),
      )
      return boards
  }
  catch(error) {
      console.log("Could not list boards", error)
      return []
  }
}
const addWhereToQuery = (query, filter) => {
  if(filter && filter.length == 3){
      return query.where(...filter)
  }
}

const removeImageBg = async ({ image, id }) => {
  await import("firebase/functions")
  const removeImageBgFunc = app.functions().httpsCallable("removeImageBg")
  const removeImageBgResult = await removeImageBgFunc({ image, id })
  return await loadProductImage(id)
}

const listCustomers = async ({
  limit = 20,
  sortBy = { field: "trial_expire", order: "desc" },
  startAfter,
  filterUser,
}) => {
  try {
    const customersRef = await customers()

    // If filterUser is provided, return the document data directly without the id
    if (filterUser) {
      const doc = await customersRef.doc(filterUser).get()
      if (doc.exists) {
        return [{ id: doc.id, ...doc.data(), doc }]
      } else {
        console.log(`No document found for user ${filterUser}`)
        return null
      }
    }

    let query = customersRef
    query = query.orderBy(sortBy.field, sortBy.order)
    if (startAfter) {
      query = query.startAfter(startAfter)
    }

    const customersRes = await query.limit(+limit).get()

    return customersRes.docs.map(doc => {
      return { id: doc.id, ...doc.data(), doc }
    })
  } catch (error) {
    console.log("Could not list customers", error)
    return []
  }
}


export { loadBoardOnce, listBoards, loadProductImage, removeImageBg, listCustomers }