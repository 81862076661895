import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { FirebaseContext } from "./Firebase";
import ImageUploading from "react-images-uploading";
import { v4 as uuidv4 } from "uuid";
import { LayoutConditionsDisplay } from './LayoutConditionsDisplay'

export const ImageUpload = ({ 
  onImageAdded = () => { }, 
  handleChange, 
  isOwner, 
  moodBoard, 
  setCustomInputValue, 
  setCustomInput, 
  setIsRewriting, 
  setIsCustomInputFocused,
  setLayoutConditions = () => {},
  setCanGenerate = () => {},
  designMode,
  setDesignMode
}) => {
  const fb = useContext(FirebaseContext);
  const [clipBoardInfo, setClipBoardInfo] = useState(null);
  const { uploadUserInputImages, app, user } = fb;
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [layoutDescription, setLayoutDescription] = useState(null);
  const [designSuggestions, setDesignSuggestions] = useState(null);
  const [layoutConditions, setLayoutConditionsLocal] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [selectedRoomType, setSelectedRoomType] = useState('');

  useEffect(() => {
    if (isOwner && moodBoard?.imageUpload) {
      setImage(moodBoard.imageUpload);
      onImageAdded(moodBoard.imageUpload);
      
      if (moodBoard.layoutDescription && moodBoard.designSuggestions) {
        setLayoutDescription(moodBoard.layoutDescription);
        setDesignSuggestions(moodBoard.designSuggestions);
      } else if (moodBoard.gptPrompt) {
        setLayoutDescription(moodBoard.gptPrompt);
        setDesignSuggestions("");
      }
    }
  }, [isOwner, moodBoard]);

  useEffect(() => {
    // Disable generation if in Restyling mode without an image
    setCanGenerate(!(designMode === 'Restyling' && !image));
  }, [designMode, image, setCanGenerate]);

  const onChange = async (imageList, addUpdateIndex) => {
    setImage(null);
    setLayoutDescription(null);
    setDesignSuggestions(null);
    setLayoutConditionsLocal(null);
    setClipBoardInfo(t('image-upload.success'));
    setIsAnalyzing(true);

    const id = uuidv4();
    const image = await uploadUserInputImages(id, imageList[0].file);
    
    const docRef = app.firestore().collection("inputPhotos").doc(id);
    await docRef.set({
      imageUpload: image,
      uid: user.uid,
      created: app.firestore.FieldValue.serverTimestamp(),
    })
    setIsRewriting(true);
    setIsCustomInputFocused(true);

    docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (data.layoutDescription && data.designSuggestions) {
          const combinedDescription = `${data.layoutDescription}\n\n ${data.designSuggestions}`;
          setCustomInputValue(combinedDescription);
          setCustomInput(combinedDescription);
          setIsRewriting(false);
          
          if (data.layoutConditions) {
            setLayoutConditions(data.layoutConditions);
            setLayoutConditionsLocal(data.layoutConditions);
          }
          setIsAnalyzing(false);
        }
      }
    });

    setImage(image);
    onImageAdded(image);
    setClipBoardInfo(null);
  };

  const handleDesignModeChange = (mode) => {
    setDesignMode(mode);
    // Enable/disable generation based on mode and image presence
    setCanGenerate(!(mode === 'Restyling' && !image));
  };

  const handleStyleClick = (styleName) => {
    if (designMode === 'Restyling' && !image) {
      return; // Don't proceed if in Restyling mode without an image
    }
    const description = `Redesign this ${selectedRoomType.toLowerCase()} in a ${styleName} style`;
    setCustomInputValue((prev) => `${prev}\n\nStyle: ${description}`);
    setCustomInput((prev) => `${prev}\n\nStyle: ${description}`);
  };

  return (
    <>
      {clipBoardInfo && (
        <p
          className={`border mt-3 p-3 text-base flex items-center justify-center rounded-lg ${clipBoardInfo.startsWith("Ooops")
              ? "border-red-900 text-red-900"
              : ""}`}
        >
          {clipBoardInfo}
        </p>
      )}
      <div className={`flex flex-col gap-3 max-w-lg mx-auto w-full transition-all duration-700 ease-in-out ${!image ? 'mb-4' : 'mb-3'}`}>
        <div className={`flex gap-2 items-center w-full ${isAnalyzing ? 'flex-row' : 'flex-col'}`}>
          <div className={`bg-gray-100 rounded-full p-1 flex items-center ${isAnalyzing ? 'flex-1 min-w-[180px]' : 'w-full'}`}>
            <button
              onClick={() => handleDesignModeChange('Restyling')}
              className={`flex-1 px-3 ${isAnalyzing ? 'py-1.5 text-xs' : 'py-2.5 text-sm'} rounded-full font-medium text-center ${
                designMode === 'Restyling'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Restyling
            </button>
            <button
              onClick={() => handleDesignModeChange('Inspiration')}
              className={`flex-1 px-3 ${isAnalyzing ? 'py-1.5 text-xs' : 'py-2.5 text-sm'} rounded-full font-medium text-center ${
                designMode === 'Inspiration'
                  ? 'bg-white text-gray-900 shadow-sm'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              Inspiration
            </button>
          </div>

          <ImageUploading onChange={(imageList) => handleChange(onChange, imageList)} maxNumber={1}>
            {({
              onImageUpload, isDragging, dragProps,
            }) => (
              <button
                onClick={onImageUpload}
                className={`flex items-center justify-center gap-1.5 ${
                  isAnalyzing 
                    ? 'px-3 py-[9px] text-xs bg-pink-600 text-white shadow-sm flex-1 min-w-[100px] hover:bg-pink-700' 
                    : 'px-4 py-2.5 text-sm w-full bg-pink-600 text-white hover:bg-pink-700 shadow-sm'
                } rounded-full font-medium ${
                  isDragging 
                    ? "bg-pink-700 text-white" 
                    : ""
                }`}
                {...dragProps}
              >
                <svg 
                  className={`${isAnalyzing ? 'w-3 h-3' : 'w-4 h-4'}`}
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="2" 
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                <span>Upload</span>
              </button>
            )}
          </ImageUploading>
        </div>
        {designMode === 'Inspiration' && !image && (
          <p className="text-xs text-gray-400 text-center -mt-1 w-4/5 mx-auto">Upload a mood board or design detail for Inspiration, or skip to generate a photo from text.</p>
        )}
        {designMode === 'Restyling' && !image && (
          <p className="text-xs text-gray-400 text-center -mt-1">Upload a photo of your room to get started.</p>
        )}
      </div>

      {/* Image preview container */}
        {image && (
          <div className="mb-3 transition-all duration-700 ease-in-out">
            <div className="flex items-center gap-3 p-3 bg-white rounded-lg shadow-sm border border-gray-100">
              <div className="w-12 h-12 flex-shrink-0 overflow-hidden rounded-md">
                <img 
                  src={image} 
                  className="w-full h-full object-cover transition-all duration-700 ease-in-out" 
                  alt="Uploaded room"
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm text-gray-500 transition-all duration-700 ease-in-out">
                  {isAnalyzing ? 'Analyzing image...' : 'Ready for redesign'}
                </p>
              </div>
              {isAnalyzing && (
                <div className="flex-shrink-0 transition-opacity duration-700 ease-in-out">
                  <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div>
              )}
            </div>
          </div>
        )}

      {image && layoutConditions && (
        <div className="">
          <LayoutConditionsDisplay conditions={layoutConditions} />
        </div>
      )}

    </>
  );
};
