import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

export const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
        apiKey: process.env.NEXT_PUBLIC_TYPESENSE_READ_API,
        nodes: [
            {
                host: process.env.NEXT_PUBLIC_TYPESENSE_HOST,
                port: process.env.NEXT_PUBLIC_TYPESENSE_PORT,
                protocol: process.env.NEXT_PUBLIC_TYPESENSE_PROTOCOL,
            }
        ],
        'numRetries': 3,
        'connectionTimeoutSeconds': 120,
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below. 
    //  queryBy is required. 
    additionalSearchParameters: {
        queryBy: "seo_title, seo_description, colorCombination",
        sort_by: "created:desc",
        group_by: "style, colorCombination",
        group_limit: 1,
        per_page: 50
    }
});
